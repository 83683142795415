import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import {background, better, buy, educate, help, hero, improve, interest, loan, success} from "../Images/images";
import Buttons from "../Buttons/Buttons";
const Home = () => {
  return (
    <section>
      <Row
        style={{
          background: `url(${background})`,
          backgroundSize: "cover", // Ensures the background covers the entire area
          backgroundRepeat: "no-repeat", // Ensures the background doesn't repeat
          backgroundPosition: "center", // Centers the background image
          // backgroundAttachment: "fixed"
        }}
        className="row-main g-0 row-overlay p-sm-1 p-md-5"
      >
        <Col
          sm={12}
          md={6}
          className="d-flex align-items-center justify-content-end p-2"
        >
          <div className="d-flex flex-column gap-3">
            <h3>Welcome to</h3>
            <h1 className="hero">Credit Guider</h1>
            <h6>Repair Your Credit & Qualify for the Loans You Deserve</h6>
            <Buttons />
          </div>
        </Col>
        <Col sm={12} md={6} className="centered"></Col>
      </Row>
      <Container>
        <Row className="justify-content-between">
          <Col className="centered" sm={12} md={7}>
            <img className="img-fluid" src={hero} alt="" />
          </Col>
          <Col className="centered flex-column" sm={12} md={5}>
            <h1>About Us</h1>
            <h4>We Help You Build the Credit You Deserve</h4>
            <p>
              We are a team of dedicated credit repair specialists passionate
              about helping people achieve their financial goals. We understand
              the challenges of navigating the complex world of credit, and
              we're here to guide you through every step of the process.
            </p>
            <Buttons />
          </Col>
        </Row>
          <Row className="gap-3 justify-content-evenly">
            <Col sm={12} md={5}>
              <div class="card text-bg-dark">
                <img src={help} class="card-img" alt="..." />
                <div class="card-img-overlay">
                  <h5 class="card-title">Card title</h5>
                  <p class="card-text">
                    This is a wider card with supporting text below as a natural
                    lead-in to additional content. This content is a little bit
                    longer.
                  </p>
                  <p class="card-text">
                    <small>Last updated 3 mins ago</small>
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={12} md={5}>
              <div class="card text-bg-dark">
                <img src={educate} class="card-img" alt="..." />
                <div class="card-img-overlay">
                  <h5 class="card-title">Card title</h5>
                  <p class="card-text">
                    This is a wider card with supporting text below as a natural
                    lead-in to additional content. This content is a little bit
                    longer.
                  </p>
                  <p class="card-text">
                    <small>Last updated 3 mins ago</small>
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={12} md={5}>
              <div class="card text-bg-dark">
                <img src={improve} class="card-img" alt="..." />
                <div class="card-img-overlay">
                  <h5 class="card-title">Card title</h5>
                  <p class="card-text">
                    This is a wider card with supporting text below as a natural
                    lead-in to additional content. This content is a little bit
                    longer.
                  </p>
                  <p class="card-text">
                    <small>Last updated 3 mins ago</small>
                  </p>
                </div>
              </div>
            </Col>
            <Col sm={12} md={5}>
              <div class="card text-bg-dark">
                <img src={success} class="card-img" alt="..." />
                <div class="card-img-overlay">
                  <h5 class="card-title">Card title</h5>
                  <p class="card-text">
                    This is a wider card with supporting text below as a natural
                    lead-in to additional content. This content is a little bit
                    longer.
                  </p>
                  <p class="card-text">
                    <small>Last updated 3 mins ago</small>
                  </p>
                </div>
              </div>
            </Col>
          </Row>
        <Row>
          <h1>Benefits</h1>
          <h2>Unlock a World of Possibilities with Good Credit</h2>
          <Col sm={12} md={6}>
            <img className="img-fluid" src={interest} alt="alt" />
          </Col>
          <Col sm={12} md={6}>
          </Col>
          <Col sm={12} md={6}>
          </Col>
          <Col sm={12} md={6}>
            <img className="img-fluid" src={loan} alt="alt" />
          </Col>
          <Col sm={12} md={6}>
            <img className="img-fluid" src={better} alt="alt" />
          </Col>
          <Col sm={12} md={6}>
          </Col>
          <Col sm={12} md={6}>
          </Col>
          <Col sm={12} md={6}>
            <img className="img-fluid" src={buy} alt="alt" />
          </Col>
          <Buttons/>
        </Row>
        <Row>
          <h1>Why Choose Credit Guider?</h1>
          <Col>
            <p>We are experienced and have a proven track record of success.</p>
          </Col>
          <Col>
            <p>We offer transparent pricing and flexible payment options.</p>
          </Col>
          <Col>
            <p>We are committed to providing excellent customer service.</p>
          </Col>
          <Col>
            <p>We are secure and protect your confidential information.</p>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Home;
