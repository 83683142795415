import "bootstrap/dist/css/bootstrap.min.css";
import { Suspense, lazy } from "react";
import { Routes, Route } from "react-router-dom";
import MyLoader from "./Assets/MyLoader/MyLoader.jsx";
import Navibar from "./Assets/Navibar/Navibar.jsx";
import Footer from "./Assets/Footer/Footer.jsx";
import SeoHelmet from "./Assets/SeoHelmet/SeoHelmet.jsx";
import Home from "./Assets/Home/Home.jsx";

const NotFound = lazy(() => import("./Assets/NotFound/NotFound.jsx"));
const Blog = lazy(() => import("./Assets/Blog/Blog.jsx"));
const Faqs = lazy(() => import("./Assets/Faqs/Faqs.jsx"));
const Terms = lazy(() => import("./Assets/Terms/Terms.jsx"));
const Policy = lazy(() => import("./Assets/Policy/Policy.jsx"));

const App = () => {
  return (
    <main>
      <SeoHelmet />
      <Navibar />
      <Suspense fallback={<MyLoader />}>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/faqs" element={<Faqs />} />
          <Route path="/terms" element={<Terms />} />
          <Route path="/policy" element={<Policy />} />
        </Routes>
      </Suspense>
      <Footer />
    </main>
  );
};

export default App;
