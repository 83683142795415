import { Helmet } from 'react-helmet';

const SeoHelmet = () => {
    return (
        <Helmet>
            <meta name="description" content="Credit Guider offers expert financial advice, tailored investment strategies, and comprehensive financial planning to help you achieve your financial goals." />
            <meta name="keywords" content="financial solutions, investment strategies, financial planning, financial advice, Credit Guider" />
            <link rel="canonical" href="https://www.creditguider.pro/" />
            <meta property="og:title" content="Credit Guider - Your Guide in Financial Success" />
            <meta property="og:description" content="Credit Guider offers expert financial advice, tailored investment strategies, and comprehensive financial planning to help you achieve your financial goals." />
            <meta property="og:url" content="https://www.creditguider.pro/" />
            <meta property="og:type" content="website" />
            <meta property="og:image" content="https://www.creditguider.pro/og-image.jpg" />
            <meta name="twitter:card" content="summary_large_image" />
            <meta name="twitter:title" content="Credit Guider - Your Guide in Financial Success" />
            <meta name="twitter:description" content="Credit Guider offers expert financial advice, tailored investment strategies, and comprehensive financial planning to help you achieve your financial goals." />
            <meta name="twitter:image" content="https://www.creditguider.pro/twitter-image.jpg" />
        </Helmet>
    );
};

export default SeoHelmet;
